import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import SEO from "../components/seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  let featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Navigation isAbsolute={false} currentPage="blog" inverted />
      <div className="bg-vanilla-ice">
        <div
          className="mb-2 bg-center bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${featuredImgFluid.src})`, height: 340 }}
        ></div>
        <section className="container py-12 pt-8 lg:py-20 lg:pt-10">
          <Link to="/blog" className="text-mulberry-500 hover:underline">← Zurück zur Übersicht</Link>
          <div className="blog-post mt-8">
            <div className="lg:text-lg tracking-wider text-gray-500 uppercase font-display font-bold mb-1 md:mb-2">
              {frontmatter.date} | by {frontmatter.author}
            </div>
            <h1 className="text-4xl lg:text-5xl leading-none font-display font-bold text-gradient">
              {frontmatter.title}
            </h1>
            <hr className="bg-meteor-500 bg-gradient-l-mulberry-500 h-1 border-0 w-1/5 mt-4 mb-16" />
            <div
              className="blog-post-content copied-text"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </section>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        author
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
